import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CsActivityLogApiService} from '@app/shared/cs-activity-log/cs-activity-log-api.service';
import {
    ICsActivityLogExternalEventParams,
    ICsActivityLogFilterParams
} from '@app/shared/cs-components/cs-components.interface';
import {
    TCsActivityEvent,
    TCsActivityLogEvent
} from "@app/shared/cs-components/cs-components.types";
import {map} from "rxjs/operators";
import {Subject} from "rxjs/internal/Subject";

@Injectable({
    providedIn: 'root'
})
export class CsActivityLogEventService {
    customerType: string;
    customerId: number;
    module: string;
    moduleId: number;
    tableType = 2;

    private activityLogData = new BehaviorSubject<{ eventData: any, event: TCsActivityLogEvent }>(null);
    onActivityLogEvent$ = this.activityLogData.asObservable();

    private activityEventData = new BehaviorSubject<{ eventData: object, event: TCsActivityEvent }>(null);
    onActivityEvent$ = this.activityEventData.asObservable();

    private timelinePreloadData = new BehaviorSubject({});
    onTimelinePreloadDataEvent$ = this.timelinePreloadData.asObservable().pipe(map(value => {
        return {
            assignee: value['assignee'] || [],
            attachedFiles: value['attachedFiles'] || [],
            activityTypes: value['activityType'] || []
        };
    }));

    private refreshPinnedNotes = new Subject();
    onRefreshPinnedNotes$ = this.refreshPinnedNotes.asObservable();

    private refreshUpcomingActivities = new Subject();
    onRefreshUpcomingActivities$ = this.refreshUpcomingActivities.asObservable();

    private pinnedNoteData = new Subject();
    onViewPinnedNote$ = this.pinnedNoteData.asObservable();

    private scheduledActivityCompletionData = new Subject();
    onScheduledActivityCompletion$ = this.scheduledActivityCompletionData.asObservable();

    private externalEvent = new Subject();
    onExternalEvent$ = this.externalEvent.asObservable();

    constructor(private _alApi: CsActivityLogApiService) {
    }

    setInitialParams(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number
    ) {
        this.customerType = customerType;
        this.customerId = customerId;
        this.module = module;
        this.moduleId = moduleId;

    }

    notifyUpdateActivityLogData(eventParams: ICsActivityLogFilterParams, event: TCsActivityLogEvent) {
        this.setActivityLogData(eventParams, event);
    }

    notifyEditActivityEvent(data) {
        this.activityEventData.next({eventData: data, event: 'EDIT'});
    }

    notifyPostEditActivity(data) {
        this.activityLogData.next({eventData: data, event: 'ACTIVITY_EDIT'});
    }

    notifyDeleteActivityEvent(data) {
        this.activityEventData.next({eventData: data, event: 'DELETE'});
    }

    notifyPostDeleteActivity(data) {
        this.activityLogData.next({eventData: data, event: 'ACTIVITY_DELETE'});
    }

    notifyRefreshPinnedNotes() {
        this.refreshPinnedNotes.next();
    }

    notifyRefreshUpcomingActivities() {
        this.refreshUpcomingActivities.next();
    }

    notifyViewPinnedNote(data) {
        this.pinnedNoteData.next(data);
    }

    notifyScheduledActivityCompletion(data?: object) {
        this.scheduledActivityCompletionData.next(data);
    }

    notifyExternalEvent(params: ICsActivityLogExternalEventParams) {
        this.externalEvent.next(params);
    }

    setActivityLogData(filterParams, event: TCsActivityLogEvent) {
        this.getActivityLogData(filterParams).subscribe(res => {
            this.activityLogData.next({eventData: res, event: event});
        });
    }

    getActivityLogData(filterParams) {
        return this._alApi.getActivityLogData(
            this.customerType,
            this.customerId,
            this.module,
            this.moduleId,
            filterParams
        );
    }

    setTimelinePreloadData() {
        this._alApi.getTimelinePreloadData(
            this.customerId,
            this.moduleId,
            this.tableType
        ).subscribe(res => {
            this.timelinePreloadData.next(res);
        });
    }

    resetActivityLogData() {
        this.activityLogData.next({eventData: null, event: null});
    }

    resetActivityEventData() {
        this.activityEventData.next(null);
    }
}
