<router-outlet></router-outlet>

<!-- Toast box -->
<div *ngIf="showToastMessage" csToastBox [message]="toastConfig.message" [duration]="toastConfig.duration"></div>

<!--For navigating across angular versions-->
<a id="navigator" style="display: none"></a>
<ng-container *ngIf="isAuthenticated">
    <app-shared-modal></app-shared-modal>
</ng-container>
