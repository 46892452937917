<ng-container *ngIf="showActivityModal">
    <div class="modal fade activityModal__" tabindex="-1" data-backdrop="false" role="dialog"
        aria-labelledby="myLargeModalLabel" id="modalPopUp">
        <div id="activity-modal-dialog" class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" translate>
                        Schedule.activity
                        <span class="pointer-cursor float-right" (click)="closeModal()">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span>
                    </h4>
                </div>
                <div class="modal-body">
                    <!-- schedule activity -->
                    <ng-container>
                        <div class="relative-container">
                            <form *ngIf="scheduleForm" [formGroup]="scheduleForm">
                                <input autofocus type="text" placeholder="Description"
                                    class="form-blk schedule-activity-description" maxlength="150"
                                    formControlName="description">
                                <textarea formControlName="notes"
                                    class="form-blk schedule-height schedule-activity-notes"
                                    placeholder="Notes"></textarea>
                                <div class="note-border">
                                    <div class="note-container">
                                        <div class="float-left">
                                            <span class="label-text margin-right10">Type :</span>
                                            <span class="pointer-cursor padding-3" *ngFor="let type of listActivity"
                                                (click)="selectedType(type)"
                                                [ngClass]="{'task-buttons': type?.id == selectedActivityType}">
                                                <ng-container *ngIf="type?.name=='Call'">
                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                    <span> {{type?.name}}</span>
                                                </ng-container>
                                                <ng-container *ngIf="type?.name=='Email'">
                                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                                    <span> {{type?.name}}</span>
                                                </ng-container>
                                                <ng-container *ngIf="type?.name=='Meeting'">
                                                    <i class="fas fa-calendar-alt" aria-hidden="true"></i>
                                                    <span> {{type?.name}}</span>
                                                </ng-container>
                                                <ng-container *ngIf="type?.name=='Task'">
                                                    <i class="fa fa-list-ul" aria-hidden="true"></i>
                                                    <span> {{type?.name}}</span>
                                                </ng-container>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="date-container" style="width: 100%;">
                                    <div class="note-borders">
                                        <div class="note-containers">
                                            <div class="float-lefts dropdown-date" id="dropdownd1">
                                                <span class="label-text" translate>Due.date.and.time</span>
                                                <div id="dropdownMenuDateButton" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false"
                                                    class="dropdown dropdown-datepicker pointer-cursor"
                                                    style="display: inline-flex;vertical-align: middle; align-items: center;">
                                                    <div class="scheduledDate-section">
                                                        <span *ngIf="selectedDate !='Custom date'">
                                                            {{selectedDate}}</span>
                                                        <span *ngIf="selectedDate == 'Custom date' && countryDetails['code'] == 44">
                                                            {{selectedCustomDate |
                                                            date:'dd/MM/yyyy'}}</span>
                                                        <span *ngIf="selectedDate == 'Custom date' && countryDetails['code'] == 1"> {{selectedCustomDate |
                                                            date:'MM/dd/yyyy'}}</span>
                                                        <input *ngIf="selectedDate !='Custom date'" type="text"
                                                            [(ngModel)]="selectedDate"
                                                            class="date-input date-input-width pointer-cursor selected-text"
                                                            formControlName="scheduledDate" id="scheduledDate" readonly
                                                            style="display: none;">
                                                        <input *ngIf="selectedDate == 'Custom date' && countryDetails['code'] == 44" type="text"
                                                            [ngModel]="selectedCustomDate | date:'dd/MM/yyyy'"
                                                            class="date-input date-input-width input-width pointer-cursor selected-text"
                                                            formControlName="scheduledDate" id="scheduledDate" readonly
                                                            style="display: none;">
                                                        <input *ngIf="selectedDate == 'Custom date' && countryDetails['code'] == 1" type="text"
                                                               [ngModel]="selectedCustomDate | date:'MM/dd/yyyy'"
                                                               class="date-input date-input-width input-width pointer-cursor selected-text"
                                                               formControlName="scheduledDate" id="scheduledDate" readonly
                                                               style="display: none;">
                                                    </div>
                                                    <i class="fa fa-caret-up dropdown-list-caret-icon" id="caret-up"
                                                        aria-hidden="true"
                                                        [ngClass]="{'dropdown-up-icon':activityDateEdit}">
                                                    </i>
                                                </div>
                                                <div class="dropdown-menu dropdown-menu1 text-colour"
                                                    aria-labelledby="dropdownMenuDateButton" id="dateList">
                                                    <!-- <span class="dropdown-menudate-arrow-activity"></span> -->
                                                    <ng-container *ngFor="let date of customDate; let dateIndex=index">
                                                        <a *ngIf="date.text != 'Custom date'"
                                                            class="dropdown-itemdate dis-flex pointer-cursor"
                                                            (click)="getFilterByDate(date?.id,date?.view_text,date?.name)"
                                                            [translateParams]="{Object: date.text_days}"
                                                            translate>{{date.text}}
                                                            <p [ngClass]="{'dropdown-menuday': date.text == 'in.business.days',
                                                    'dropdown-days':date.text == 'in.months',
                                                    'dropdown-day1': date.text == 'next.monday',
                                                    'dropdown-menudates': date.text == 'in.1.month' || date.text == 'in.weeks',
                                                    'dropdownmenu-week':date.text == 'in.1.week'}">{{date.name}}
                                                            </p>
                                                        </a>
                                                        <a *ngIf="date.text == 'Custom date'" (click)="selectedCustom()"
                                                            [minDate]="minDate" (bsValueChange)="dateCreated($event)"
                                                            #stInvDate="bsDatepicker"
                                                            container="#bs-datepicker-container" bsDatepicker
                                                            [placement]="'right'" [bsConfig]="bsDateConfig"
                                                            class="dropdown-itemdate pointer-cursor"> {{date.text}}
                                                            <i *ngIf="date.text == 'Custom date'"
                                                                class="fa fa-angle-right right-arrow">
                                                            </i>
                                                        </a>
                                                        <div class="">
                                                            <div id="bs-datepicker-container"></div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="float-lefts" style="    display: inline-block;">
                                                    <div class="dropdown dropdown-time">
                                                        <span id="scheduleTimePicker" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false"
                                                            class=" pointer-cursor margin-t10" translate>
                                                            <span
                                                                class="show-time">{{scheduleTime(scheduleForm.value.scheduleTime)}}</span>
                                                            <i class="fa fa-caret-up dropdown-list-caretdown-icon"></i>
                                                        </span>
                                                        <div class="dropdown-menu timepicker-dropdown schedule-time-align schedule-time-picker-border-shadow "
                                                            aria-labelledby="scheduleTimePicker">
                                                            <div class="top-increment">
                                                                <span class="increment-icon"><span
                                                                        (click)="timepickerIncrement('time')"> <i
                                                                            class="fa fa-chevron-up"></i> </span></span>
                                                                <span class="increment-icon"><span
                                                                        (click)="timepickerIncrement('min')"> <i
                                                                            class="fa fa-chevron-up"></i> </span></span>
                                                            </div>
                                                            <cs-timepicker
                                                                [timepickerChangeEvent]="timepickerChangeEvent.asObservable()"
                                                                [formControl]="scheduleForm.controls['scheduleTime']"
                                                                [meridian]="false" (keypress)="omitSpecialChar($event)">
                                                            </cs-timepicker>
                                                            <div class="bottom-decrement">
                                                                <span class="decrement-icon"><span
                                                                        (click)="timepickerDecrement('time')"> <i
                                                                            class="fa fa-chevron-down"></i>
                                                                    </span></span>
                                                                <span class="decrement-icon"><span
                                                                        (click)="timepickerDecrement('min')"> <i
                                                                            class="fa fa-chevron-down"></i>
                                                                    </span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <select class="full-width-select2 schedule-activity-assign" csSelect2
                                    [select2Config]="select2Config" formControlName="assigneeIds" id="assignTo" multiple
                                    (selected)="getAssignee($event)" placeholder="Assign to">
                                    <option *ngFor="let assignee of recordBasedAssigneeList" [value]="assignee?.id">
                                        {{assignee?.username}}</option>
                                </select>
                            </form>
                        </div>
                    </ng-container>
                </div>
                <!-- Footer -->
                <div class="modal-footer" [ngClass]="{'attachments-over':modalBack == true}">
                    <div class="row-fluid">
                        <div class="float-right">
                            <button class="btn disgard-button" (click)="closeModal()" translate>
                                Discard
                            </button>
                            <button *ngIf="scheduleForm" class="btn btn-primary"
                                [disabled]="!scheduleForm.valid || scheduleButtonDisabled" (click)="scheduledActivity()"
                                translate>
                                Schedule
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
