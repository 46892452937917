// @ts-ignore
export class CommonFilterService {
    private data: any = {};
    constructor() { }
    filterData(data){
        this.data['customerId'] =this.filterId(data);
        this.filterValuesByTableType(data);
        return this.data
    }
    filterId(values){
        let url = values.recipient_addresses_url;
        let propertyValue = "propertyId";
        let regexPropertyId = new RegExp(`[?&]${propertyValue}=([^&]+)`);
        let idValue = "id";
        let regexId = new RegExp(`[?&]${idValue}=([^&]+)`);
        let propertyId = this.extractParameterValue(url, regexPropertyId);
        if (!propertyId) {
            let id = this.extractParameterValue(url, regexId);
            return id
        }else{
            return propertyId
        }
    }

    filterValuesByTableType(values) {
        switch (values.relatedObjectType) {
            case 2: //CUSTOMER
                this.data['screenType']='customer';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 3: //SUPPLIER
                this.data['screenType']='supplier';
                this.data['tablePKId']=values.relatedObjectId;//supplierid
                break;

            case 5: //ESTIMATES
                this.data['screenType']='estimate';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 6: //PURCHASE_ORDER
                this.data['screenType']='purchaseOrder';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 7: //INVOICE_ADDRESS
                this.data['screenType']='invoiceAddress';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 19: //SUPPLIER_INVOICE
                this.data['screenType']='supplierInvoice';
                this.data['tablePKId']=values.relatedObjectId;//supplierinvoiceid
                break;

            case 23: //CREDIT_NOTES
                this.data['screenType']='creditNote';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 31: //JOBS
                this.data['screenType']='jobs';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 54: //CUSTOMER_PAYMENTS
                this.data['screenType']='payment';
                this.data['tablePKId']=values.relatedObjectId;
                let invoiceUrl = values.attachments_url;
                let regex = /[?&]id=([^&]+)/;
                this.data['invoiceId']=this.extractParameterValue(invoiceUrl,regex);
                break;

            case 57: //CUSTOMER_CREDIT_NOTES
                this.data['screenType']='customerCreditNote';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 59: //CUSTOMER_INVOICES
                let urls = values.attachments_url;
                this.data['screenType']='invoice';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 60: //JOB--SENDJOBREPORT
                this.data['screenType']= 'jobs';
                this.data['tablePKId']= values.relatedObjectId;
                break;

            case 61: //CUSTOMER-SEND STATEMENTS
                this.data['screenType']='customer';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 64: //INVOICE_ADDRESS_SENDSTATEMENTS
                this.data['screenType']='invoiceAddress';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 110: //additional work from job
                this.data['screenType']='additionalWork';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 117: //ESTIMATE_PDF_SENT_TO_CUSTOMER
                this.data['screenType']='estimate';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 128: //CUSTOMER_INVOICES_COMMUNICATION
                let url = values.attachments_url;
                let regexId = /[?&]id=([^&]+)/;
                this.data['jobId']=this.extractParameterValue(url,regexId);
                this.data['screenType']='invoice';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 301: //contract view
                this.data['screenType']='contract';
                this.data['tablePKId']=values.relatedObjectId;
                break;

            case 232: //opportunity view
                this.data['screenType'] = 'opportunity';
                this.data['tablePKId']=values.relatedObjectId;
               break;

            case 166: //multiple Invoice
                this.data['screenType']='invoice';
                this.data['tablePKId']=values.relatedObjectId;
               break;
        }
    }

    extractParameterValue(url, regex) {
        let match = url.match(regex);
        if (match && match.length > 1) {
           let value = match[1];
         return value;
        }
   }
}
