import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FolderService } from '../service/folder.service';
import { SidepanelModalService } from '@app/sidepanel/services/sidepanel-modal.service';
import { BsModalRef } from 'ngx-bootstrap';
import { TemplateModalComponent } from '../template-modal/template-modal.component';
import {AuthService} from "@app/core";

@Component({
    selector: 'app-search-template',
    templateUrl: './search-template.component.html',
    styleUrls: ['./search-template.component.scss']
})
export class SearchTemplateComponent implements OnInit {

    selectedFolder: any = '';
    loading: boolean = false;
    searchValue: any = '';
    parentId: any;
    timeDelay: any = "";
    templateList: any = [];
    templateSearchList: any = [];
    @Input() treeData: any = [];
    templateData: any = {};
    @Input() screen: String = '';
    @Input() type: string = '';
    @Input() tablePKId: number;
    @Input() isFolderSelectable: boolean = false;
    @Input() showAddNewTemplate: boolean = false;
    @Input() screenLabel: string = '';
    @Input() selectedTemplate: string = '';
    @Input() selectedTemplateId: any = '';
    @Input() themeName: string = 'default';
    @Input() placeHolder: string = '-- Please choose --';
    initialScreenLabel:string = '';
    bsModalRef: BsModalRef;
    currentRow: any;
    filterParams: any = {
        screen: '',
        parentId: null,
        search: ''
    };
    selectedFolderId: number;
    showFolderBorder: boolean = false;
    @Output() selectedItem = new EventEmitter<string>();
    @Input() viewMode: boolean = false;
    freemiumAccess: boolean = false;
    @Input() excludeOptions:any = [];
    @Input() disabled:boolean = false;
    @Input() addon: boolean = false;
    @Input() openDropDown = false;
    @Input() isArchived: boolean = false;

    constructor(private folderService: FolderService,
        private sidepanelModalService: SidepanelModalService,private authService: AuthService) { }

    ngOnInit() {
        this.filterParams.screen = this.screen;
        this.filterParams.type = this.type;

        if(this.selectedTemplateId){
            this.getSelectedTempate();
        }
        if (this.treeData.length) {
            this.templateList = this.treeData;
            this.setCurrentSelectedRow();
        }
        else {
            this.getTemplatesList();
        }
        let proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if(!proposalEditor && (this.screen == 'option_template' || this.screen == 'proposal_template' || this.screen == 'opportunity_template')){
            this.freemiumAccess = true;
        } else {
            this.freemiumAccess = false;
        }

        if(this.openDropDown){
            setTimeout(()=>{
                $('.template-object').addClass('open');
            },1000);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this.screenLabel && changes.hasOwnProperty('screenLabel') && changes.screenLabel.firstChange){
            this.initialScreenLabel = this.screenLabel;
        }
        if(this.selectedTemplate && changes.hasOwnProperty('selectedTemplate')){
            this.screenLabel = this.selectedTemplate;
            if(!changes.selectedTemplate.firstChange){
                this.clearSearch();
            }
        }

        if(this.openDropDown){
            setTimeout(()=>{
                $('.template-object').addClass('open');
            },1000);
        }
    }


    getSelectedTempate(){
        let url = 'get_template_details/'+this.selectedTemplateId+'?screen='+this.screen;
        this.folderService.getApiCall(url).subscribe((data:any) =>{
            if(data.length && data[0].templateName){
                this.screenLabel = data[0].templateName;
            }
        })
    }

    setCurrentSelectedRow() {
        let _this = this;
        this.templateList.forEach(function iter(currentChildren, key) {
            Array.isArray(currentChildren.children) &&
                currentChildren.children.forEach(iter);
            if (currentChildren.selected === true) {
                _this.screenLabel = currentChildren.value
                _this.currentRow = currentChildren;
            }
        });
    }


    getTemplatesList(showInitialVal = true) {
        this.loading = true;
        let listUrl = "list_templates";
        if (showInitialVal) {
            this.templateList = [{
                value: this.initialScreenLabel,
                id: null,
                parentId: null,
                isFolder: "1",
                isOpen: true,
                selected: true,
                deleted: 0,
                children: [],
            }];
            this.currentRow = this.templateList[0];
        }
        else {
            this.templateList = [];
        }
        if(this.screen == 'option_template'){
            this.filterParams['addOn'] = this.addon;
        }
        this.folderService.postApiCall(listUrl, this.filterParams).subscribe((templateData: any) => {
            if (templateData) {
                this.loading = false;
                if (!this.searchValue && showInitialVal && !this.selectedFolder) {
                    this.templateList[0].children = templateData.data;
                } else {
                    this.templateList = templateData.data;
                }
                if(this.screen == 'option_template' || this.screen == 'proposal_template' || this.screen == 'opportunity_template') {
                    this.excludeOptionTemplates();
                }
                // let templateIndex = currentRow.children.findIndex(x => x.templateId == value.templateId);
                // if(templateIndex !== -1){
                //     currentRow.children[templateIndex].selected = true;
                // }
            }
        })
    }

    initializeSearch(event?) {
        let searchVal = event.target.value;
        if(!this.filterParams.search && !searchVal){
            this.removeFolder(event);
        }
        else{
            this.showFolderBorder = false;
        }
        this.loading = true
        let _self = this;
        clearTimeout(this.timeDelay);
        this.timeDelay = setTimeout(() => {
            _self.searchValue = _self.filterParams.search = searchVal;
            _self.getTemplatesList();
        }, 500);
    }

    removeFolder(event){
        if(event && event.key === 'Backspace'){
            if(!this.filterParams.search && this.selectedFolder && !this.showFolderBorder){
                this.showFolderBorder = true;
            }
            else if(!this.filterParams.search && this.selectedFolder && this.showFolderBorder){
                this.showFolderBorder = false;
                this.clearSearch();
            }
        }
        else{
            this.showFolderBorder = false;
        }
    }

    viewChild(currentRow, loadInitialData = false) {
        if (currentRow.isFolder && !this.searchValue && !this.selectedFolder) {
            if (typeof currentRow.isOpen != 'undefined' && (currentRow.isOpen || !currentRow.hasOwnProperty('isOpen'))) {
                currentRow.isOpen = false;
            }
            else if (currentRow.visited && !currentRow.isOpen) {
                currentRow.isOpen = true;
            }

            else {
                $(".folder-left-view").addClass("disable-click");
                currentRow.isOpen = true;
                currentRow.loading = true;
                let fodlerListUrl = "list_templates";
                let params = {
                    parentId: currentRow.id,
                    screen: this.screen,
                    type: this.type
                }
                currentRow.children = [];
                this.folderService
                    .postApiCall(fodlerListUrl, params)
                    .subscribe((listData: any) => {
                        let data = listData.data;
                        if (this.excludeOptions && this.excludeOptions.length > 0 ) {
                            data = data.filter(item => !this.excludeOptions.includes(item.templateId));
                        }
                        currentRow.loading = false;
                        if (data && data.length) {
                            data.forEach((val) => {
                                currentRow.children.push(val);
                            });
                        }
                        currentRow.isOpen = true;
                        currentRow.visited = true;
                        $(".folder-left-view").removeClass("disable-click");
                    });
            }
        }
        else if ((currentRow.isFolder && this.searchValue) || this.selectedFolder) {
            this.filterParams.search = '';
            this.searchValue = '';
            this.showFolderBorder = false;
            this.selectedFolder = currentRow.value;
            this.filterParams.parentId = currentRow.id;
            this.getTemplatesList();
        }
    }

    selectFolder(item) {
        this.currentRow.selected = false;
        if (this.isFolderSelectable) {
            $('.template-object').removeClass('open');
            this.currentRow.selected = false;
            this.selectedFolderId = item.id;
            item.selected = true;
            if (item.value) {
                this.placeHolder = '';
                this.screenLabel = item.value;
            }
            else if (item.templateName) {
                this.screenLabel = item.templateName;
            }
            let parentIds = this.getParents(this.templateList, []);
            if (typeof parentIds === 'object' && parentIds.length) {
                item.parentIds = parentIds.reverse();
            }
            this.selectedItem.emit(item);
            this.currentRow = item;
        }
        else if (item.isFolder == '1') {
            this.filterParams.search = '';
            this.searchValue = '';
            if (item.value) {
                this.selectedFolder = item.value;
            }
            else if (item.templateName) {
                this.selectedFolder = item.templateName;
            }
            this.filterParams.parentId = item.id;
            this.getTemplatesList(false);
        }
        else {
            $('.template-object').removeClass('open');
            if (item.value) {
                this.screenLabel = item.value;
            }
            else if (item.templateName) {
                this.screenLabel = item.templateName;
            }
            item.selected = true;
            this.currentRow = item;
            this.selectedItem.emit(item);
        }
    }

    getParents(list, parentIds) {
        for (var i = 0; i < list.length; i++) {
            var node = list[i];

            if (node.id === this.selectedFolderId) {
                // If the current node is the child, add its ID to the parentIds array
                parentIds.push(node.id);
                return true;
            }

            if (node.children && node.children.length > 0) {
                // If the current node has children, recursively search in its children
                if (this.getParents(node.children, parentIds)) {
                    // If the child is found in the children, add the current node's ID to the parentIds array
                    parentIds.push(node.id);
                    return parentIds;
                }
            }
        }

        return parentIds;
    }

    clearSearch() {
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.searchValue = '';
        this.selectedFolder = '';
        this.getTemplatesList();
    }

    cancelTemplate() {
        $("#emailcontent").removeClass("overlay");
        $(".template-wrapper").removeClass("template-open");
    }

    addNewTemplate() {
        if (this.screen == 'email_template') {
            this.openEmailTemplateWidget();
        }
        else if (this.screen == 'sms_template') {
            this.openSmsTemplateWidget();
        }
        else if (this.screen == 'letter_template') {
            this.openLetterTemplateWidget();
        }
    }

    openEmailTemplateWidget() {
        let initialState = {
            title: "add.email.templates",
            screenLabel: "email.templates",
        }

        this.bsModalRef = this.sidepanelModalService.show(
            TemplateModalComponent,
            {
                initialState: initialState,
                size: 'xl',
            }
        );
        this.bsModalRef.content.onClose.subscribe((result) => {
            if (result) {
                this.handleSelectedTreeView(result)
                this.selectedItem.emit(result);
                this.clearSearch();
            }
        });
    }

    async handleSelectedTreeView(value) {
        this.screenLabel = value.templateName;
        this.currentRow.selected = false;
        if (value && value.hasOwnProperty('parentIds')) {
            let parentIds = value.parentIds;
            let currentRow;
            if(parentIds.length == 0 && value.hasOwnProperty('templateId') && value.templateId){
                this.getTemplatesList();
            }
            else{
                parentIds.shift();
                for (let key = 0; key < parentIds.length; key++) {
                    let folderId = parentIds[key];
                    if (key == 0) {
                        let index = this.templateList[key].children.findIndex(x => x.id == folderId);
                        console.log(index)
                        if (index !== -1) {
                            currentRow = this.templateList[key].children[index];
                            if (!currentRow.hasOwnProperty('children')) {
                                currentRow.children = [];
                            }
                            currentRow = await this.loadTreeView(currentRow, folderId);
                        }
                    }
                    else {
                        let index = currentRow.children.findIndex(x => x.id == folderId);
                        if (index !== -1) {
                            currentRow = currentRow.children[index];
                        }
                        if (!currentRow.hasOwnProperty('children')) {
                            currentRow.children = [];
                        }
                        currentRow = await this.loadTreeView(currentRow, folderId);
                    }
                    currentRow.isOpen = true;
                    let templateIndex = currentRow.children.findIndex(x => x.templateId == value.templateId);
                    if(templateIndex !== -1){
                        currentRow.children[templateIndex].selected = true;
                    }
                };
            }
        }
    }

    async loadTreeView(currentRow, folderId): Promise<void> {
        let _this = this;
        return new Promise(function (resolve, reject) {
            let fodlerListUrl = "list_templates";
            let params = {
                parentId: folderId,
                screen: _this.screen,
                type: _this.type
            }
            _this.folderService
                .postApiCall(fodlerListUrl, params)
                .subscribe((listData: any) => {
                    let data = listData.data;
                    currentRow.loading = false;
                    if (data && data.length) {
                        data.forEach((val) => {
                            let checkIndex = currentRow.children.findIndex(x => x.id == val.parentId)
                            if (checkIndex === -1) {
                                currentRow.children.push(val);
                            }
                        });
                    }
                    currentRow.isOpen = true;
                    currentRow.visited = true;
                    // let index = currentRow.children.findIndex(x => x.id == folderId);
                    // if (index !== -1) {
                        // currentRow = currentRow.children;
                    // }
                    $(".folder-left-view").removeClass("disable-click");
                    resolve(currentRow);
                });
        });

    }

    openSmsTemplateWidget() {
        let initialState = {
            title: "add.sms.templates",
            screenLabel: "sms.templates",
            screenType: 'sms_template'
        }

        this.bsModalRef = this.sidepanelModalService.show(
            TemplateModalComponent,
            {
                initialState: initialState,
                size: 'xl',
            }
        );
        this.bsModalRef.content.onClose.subscribe((result) => {
            if (result) {
                this.handleSelectedTreeView(result);
                this.selectedItem.emit(result);
                this.clearSearch();
            }
        });
    }

    openLetterTemplateWidget() {
        let initialState = {
            title: "add.letter.templates",
            screenLabel: "letter.templates",
            screenType: 'letter_template'
        }

        this.bsModalRef = this.sidepanelModalService.show(
            TemplateModalComponent,
            {
                initialState: initialState,
                size: 'xl',
            }
        );
        this.bsModalRef.content.onClose.subscribe((result) => {
            if (result) {
                this.handleSelectedTreeView(result);
                this.selectedItem.emit(result);
                this.clearSearch();
            }
        });
    }

    excludeOptionTemplates() {
        let array = [];
        if (this.excludeOptions && this.excludeOptions.length > 0 ) {
            if(this.filterParams.parentId == '' || this.filterParams.parentId == null){
                this.templateList = this.templateList[0].children.filter(item => !this.excludeOptions.includes(item.templateId));
            } else {
                this.templateList = this.templateList.filter(item => !this.excludeOptions.includes(item.templateId));
            }
        }

    }

    findPosition(event, type) {
        let element = event.target;
        let top = $(element).position().top;
        if((top >=630)) {
            top -= 42;
        } else {
            top += 46;
        }
        $(element).find('.tooltipTemplatetext').css({'top': top})
        $(element).find('.tooltipTemplatetext').css('display', 'block')
    }

    tooltipHide(type){
        $('.tooltipTemplatetext').css('display','none')
    }
}
