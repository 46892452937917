<ng-container *ngIf="showEmailModal">
    <div class="modal fade emailModal " data-backdrop="false" role="dialog" aria-labelledby="myLargeModalLabel"
        id="modalPopUp">
        <div id="email-modal-dialog" class="modal-dialog email-modal-height">
            <div class="modal-content" *ngIf="showEmailModal">
                <div class="modal-header select-cursor" (click)="emailDraft_($event)">
                    <h4 class="modal-title" id="myModalLabel">
                        <div class="dropdown" id="drop-down-emails">
                            <span translate>Send.email.from:</span>
                            <span class="select-cursor" id="fromEmailDropdown" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" (click)="openFromAddress($event)">
                                {{currentUserEmail}} &nbsp;<i class="fa fa-angle-down" aria-hidden="true"
                                    id="emailChoose"></i>
                            </span>
                            <div class="dropdown-menu dropdown-fromEmail" aria-labelledby="fromEmailDropdown"
                                id="emailList">
                                <span class="dropdown-menu-arrow-record email-right"></span>
                                <ng-container *ngFor="let address of fromAddress">
                                    <a [ngClass]="{'s-email' : address.description == currentUserEmail}"
                                        class="dropdown-item cursor-pointer"
                                        (click)="getSelectedFromAddress(address, $event)">{{address?.description}}
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                        <span class="email-pointer float-right mail-close" id="email-close">
                            <i class="fa fa-times cursor-pointer" aria-hidden="true"></i>
                        </span>
                        <span class="email-pointer float-right mail-minimize">
                            <span *ngIf="!emailDraft">
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="emailDraft">
                                <i class="fa fa-angle-up" aria-hidden="true"></i>
                            </span>
                        </span>
                    </h4>
                </div>

                <div class="modal-body" [hidden]="emailDraft">
                    <ng-container>
                        <!--Template list-->
                        <div class="template-wrapper email-template-wrapper"
                            [style.background-color]="templateList?.length==0 ? '#ffffff' : '#F2F2F2'">
                            <div class="email-search-bar-container template-search-icon" *ngIf="showTemplateList">
                                <app-search-template [screen]="'email_template'" [screenLabel]="screenLabel"
                                    [showAddNewTemplate]="showAddNewTemplate"
                                    (selectedItem)="setTemplateDetails($event)" [openDropDown] = showTemplateList></app-search-template>
                            </div>
                            <div class="template-footer">
                                <span class="template-color cursor-pointer text-float-right" (click)="cancelTemplate()"
                                    translate>Cancel</span>
                            </div>
                        </div>
                        <!--Template list-->

                        <div class="attachment-container">
                            <ng-container *ngIf="attachments?.length==0">
                                <div class="no-results with-large-top-margin">
                                    <div class="warning-icon-wrap with-info-icon">
                                        <span class="ss-info icon fix-left"></span>
                                    </div>
                                </div>
                                <div class="warning-message ng-binding text-align-center">
                                    <!-- <span translate *ngIf="!isCommonRoute">No.available.files</span> -->
                                    <span translate>No.files.are.available</span>
                                </div>
                                <div class="template-footer">
                                    <span class="cancel-button pointer-cursor float-right" (click)="cancelAttachments()"
                                        translate>Cancel
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="attachments?.length != 0">
                                <div class="template-header header-background" translate>
                                    Available.files <span class="attachment-size">- {{totalSizeofAttachment}} MB / 16
                                        MB</span>
                                </div>
                                <div class="attach-file-content attachemnt-file-bg-height">
                                    <div class="template-search margin-right-align">
                                        <div class="search">
                                            <span class="fa fa-search search-icon"></span>
                                            <input placeholder="Search" class="search-width"
                                                [(ngModel)]="filterParams.search" (keyup)="searchFiles()">
                                        </div>
                                    </div>
                                    <ul class="attachment-lists" *ngIf="!attachNotFound">
                                        <li class="template-data" *ngFor="let attachment of attachments"
                                            (click)="onNativeChange($event)">
                                            <ng-container>
                                                <input type="checkbox" [checked]="attachment?.selected"
                                                    [id]="attachment?.name" [name]="attachment?.name"
                                                    [value]="attachment?.id" class="checkbox-attachment"
                                                    [disabled]="attachment.hasOwnProperty('isRemovable')">
                                                <span
                                                    class="template-name attach-file-overflow">{{attachment?.name}}</span>
                                                {{showAttachmentName('',attachment?.size)}}
                                                <span class="large-file-ui"
                                                    *ngIf="isLargeFileSize && !attachment?.attached"
                                                    translate>file.size.large
                                                </span>
                                            </ng-container>
                                        </li>
                                    </ul>
                                    <ng-container *ngIf="attachNotFound">
                                        <div class="attachment-lists warning-container-height">
                                            <div class="warning-icon-wrap with-info-icon">
                                                <span class="ss-info icon fix-left"></span>
                                            </div>
                                            <div class="warning-message ng-binding text-align-center" translate>
                                                No.Record.Found.Search
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="attachment-footer">
                                    <div class="footer-right float-right">
                                        <span class="template-color cursor-pointer text-padding"
                                            (click)="cancelAttachments()" translate>Cancel
                                        </span>
                                        <button class="btn btn-primary mr-btn btn-align-margin"
                                            (click)="showAttachedFiles()"
                                            [disabled]="!showAttachfileButton || totalSizeofAttachment > 16"
                                            translate>Attach.file.and.return.to.email
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="email-error-message">
                            <ng-container *ngIf="showEmailError">
                                <div class="no-results-wrap with-large-top-margin">
                                    <div class="warning-icon-wrap with-info-icon with-warning-icon">
                                        <span class="ss-alert icon fix-left"></span>
                                    </div>
                                </div>
                                <div class="warning-message ng-binding text-align-center" translate>
                                    {{emailErrorMessage}}
                                </div>
                                <div class="template-footer">
                                    <span class="cancel-button pointer-cursor float-right" (click)="returnEmail()"
                                        translate>Return.to.email
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                        <ng-container>
                            <div class="emailbackdrop">
                                <form *ngIf="emailForm" [formGroup]="emailForm">
                                    <div class="job-complete" *ngIf="emailActivityShow">
                                        <label class="checkbox-button margin-btm0" for="emailComplete">
                                            <input class="checkbox-button__input" type="checkbox" id="emailComplete"
                                                [checked]="completed" (change)="completeActivity($event)" />
                                            <span class="checkbox-button__control complete-check"></span>
                                            <span class="checkbox-button__label" translate>
                                                <i class="fa fa-envelope margin-r5" aria-hidden="true"></i>
                                                <span translate>activity.email.today</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div id="emailcontent" class="main">
                                        <div class="to-email-container">
                                            <select id="emailTo" class="full-width-select2 select-blk email-receipient"
                                                csSelect2 [select2Config]="select2Config" formControlName="toAddresses"
                                                [multiple]="true" (opened)="closeDropdown()"
                                                (selected)="getToAddress($event)" placeholder="To">
                                                <option *ngFor="let customer of customerContacts" [value]="customer.id">
                                                    {{customer?.description}}</option>
                                            </select>
                                            <a class="cc-text" (click)="showCcOption()"> <span *ngIf="!showCc">Add
                                                    cc</span></a>
                                        </div>
                                        <div class="cc-email-container" *ngIf="showCc">
                                            <select id="emailCc" class="full-width-select2 select-blk cc-mail" csSelect2
                                                [select2Config]="select2Config" formControlName="ccAddresses"
                                                [multiple]="true" (opened)="closeDropdown()"
                                                (selected)="getCcAddress($event)" placeholder="Cc">
                                                <option *ngFor="let customer of ccContacts" [value]="customer.id">
                                                    {{customer?.description}}</option>
                                            </select>
                                            <a class="cc-text" (click)="removeCcOption()">Remove cc</a>
                                        </div>
                                        <div class="tiny-mce-single-line-input-wrapper subject-tiny-mce">
                                            <input type="text" id="email-template-subject" formControlName="subject"
                                                class="form-blk">
                                        </div>
                                        <textarea id="email-template-message" style="height: 400px;" rows="8" cols="70"
                                            class="controls textareatest  tiny-mce-single-line-input-wrapper"
                                            [ngClass]="{'modal-textarea':!emailActivityShow,'modalemail-textarea':emailActivityShow}"
                                            formControlName="content">
                  </textarea>
                                        <ul class="attached_files_list attach-background"
                                            *ngIf="attachedFiles.length > 0 || pdfFiles.length > 0">
                                            <li *ngIf="attachedFiles.length > attached_files_view_limit"
                                                class="view-more-link">
                                                <i>And <strong>{{attachedFiles.length -
                                                        attached_files_view_limit}}</strong>
                                                    more</i>
                                                <span class="delete-link text-only" (click)="showAttachments()">
                                                    <a translate>View.all</a>
                                                </span>
                                            </li>
                                            <ng-container *ngFor="let attached_file of attachedFiles;let i = index">
                                                <li class="flex-display" *ngIf="i < attached_files_view_limit ">
                                                    <div class="attachment-title">{{attached_file.name}}</div>
                                                    <span class="delete-link cancel-btn-margin-top"
                                                        (click)="deleteAttachedFile(attached_file)"
                                                        *ngIf="!attached_file.hasOwnProperty('isRemovable')">
                                                        <span class="ss-delete"></span>
                                                    </span>
                                                </li>
                                            </ng-container>
                                            <li *ngFor="let attached_file of pdfFiles;let i = index">
                                                <ng-container>
                                                    <span class="attachment-title">
                                                        {{attached_file.fileName}}
                                                    </span>
                                                    <span class="file-size">MB</span>
                                                    <span class="delete-link"
                                                        (click)="deleteAttachedFile(attached_file)">
                                                        <span class="ss-delete"></span>
                                                    </span>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <!-- Footer -->
                <div class="modal-footer" [hidden]="emailDraft" [ngClass]="{'attachments-over': modalBack == true }">
                    <div>
                        <div *ngIf="!sendPdf" class="float-left">
                            <ng-container>
                                <div class="float-left use-templates-mt-4">
                                    <button class="btn template-button" (click)="showTemplateOptions()"
                                        translate>Use.template</button>
                                </div>
                                <div class="attachment-button cursor-pointer" (click)="showAttachments()">
                                    <i class="fa fa-paperclip paperclip-icon" aria-hidden="true"></i>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="false">
                                <div class="attachment-button cursor-pointer" (click)="showAttachments()">
                                    <button class="btn template-button" translate>Attachments</button>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="!sendPdf && false" class="attachment-button cursor-pointer"
                            (click)="showAttachments()">
                            <div tooltip="Attachments"><i class="fa fa-paperclip paperclip-icon" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="float-right">
                            <span *ngIf="!sendPdf && !isFromJs && !showAttachment" class="float-left">
                                <div class="schedule-check" *access="['ScheduleActivity.writeaccess']">
                                    <label class="checkbox-button checkbox-text-center" for="completeScheduleActivity">
                                        <input class="checkbox-button__input" type="checkbox"
                                            id="completeScheduleActivity" [checked]="scheduled"
                                            (change)="isScheduledActivity($event)" />
                                        <span class="checkbox-button__control"></span>
                                        <span class="checkbox-button__label" translate>Schedule.new.activity</span>
                                    </label>
                                </div>
                            </span>
                            <button class="btn disgard-button" (click)="closeModalConfirmation()" translate>
                                Discard
                            </button>
                            <button *ngIf="emailForm" class="btn btn-primary"
                                [disabled]="!emailForm.valid || sendButtonDisabled" (click)="sendEmail()" translate>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
