import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { InterfaceService } from "@app/interfaces";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PropertyViewService = /** @class */ (function (_super) {
    tslib_1.__extends(PropertyViewService, _super);
    function PropertyViewService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.CustomerDetails = new BehaviorSubject('');
        _this.CustomerDetails$ = _this.CustomerDetails.asObservable();
        _this.CustomerNav = new BehaviorSubject('');
        _this.CustomerNav$ = _this.CustomerNav.asObservable();
        _this.refreshSliderScheduleActivity = new Subject();
        _this.refreshSliderScheduleActivity$ = _this.refreshSliderScheduleActivity.asObservable();
        _this.refreshSliderNotes = new Subject();
        _this.refreshSliderNotes$ = _this.refreshSliderNotes.asObservable();
        _this.customerAppliance = new BehaviorSubject('');
        _this.customerAppliance$ = _this.customerAppliance.asObservable();
        _this.timelineRefresh = new BehaviorSubject('');
        _this.timelineRefresh$ = _this.timelineRefresh.asObservable();
        _this.timelineDownload = new BehaviorSubject('');
        _this.timelineDownload$ = _this.timelineDownload.asObservable();
        _this.whoIsViewing = new BehaviorSubject('');
        _this.$whoIsViewing = _this.whoIsViewing.asObservable();
        _this.sliderNoteDetails = new BehaviorSubject('');
        _this.$sliderNoteDetails = _this.sliderNoteDetails.asObservable();
        _this.canWorkWithCustomer = new BehaviorSubject(true);
        _this.canWorkWithCustomer$ = _this.canWorkWithCustomer.asObservable();
        _this.canNotWorkWithCustomer = new BehaviorSubject('');
        _this.canNotWorkWithCustomer$ = _this.canNotWorkWithCustomer.asObservable();
        _this.timelineDetails = new BehaviorSubject('');
        _this.timelineDetails$ = _this.timelineDetails.asObservable();
        _this.modalCloseEvent = new BehaviorSubject('');
        _this.modalCloseEvent$ = _this.modalCloseEvent.asObservable();
        return _this;
    }
    PropertyViewService.prototype.getServiceReminder = function (id, type, limit) {
        var url = this.getApiUrl("customers/" + type + "/" + id + "/reminders/service_reminder/views?limit=" + limit);
        return this.http.get(url);
    };
    PropertyViewService.prototype.getCustomFieldQuestionAnswers = function (objects, objectTypes, cusTypeId, tablePKID, propertyID) {
        return this.http.get(this.getApiUrl("listCustomFieldQuestionAnswers?objects=" + objects + "&objectTypes=" + objectTypes + "&objectTypeID=" + cusTypeId + "&propertyID=" + propertyID + "&tablePKID=" + tablePKID));
    };
    PropertyViewService.prototype.setCutomerdetails = function (data) {
        this.CustomerDetails.next(data);
        var customerNavBarDetails = {};
        customerNavBarDetails['customerNav'] = data;
        this.CustomerNav.next(customerNavBarDetails);
        this.getCanWorkWithProperty(data['canWork']);
    };
    PropertyViewService.prototype.setRefreshSliderScheduleActivity = function (data) {
        this.refreshSliderScheduleActivity.next(data);
    };
    PropertyViewService.prototype.setRefreshSliderNotes = function (data) {
        this.refreshSliderNotes.next(data);
    };
    PropertyViewService.prototype.setCustomerNav = function (data) {
        this.CustomerNav.next(data);
    };
    PropertyViewService.prototype.beforeDelete = function (id) {
        return this.http
            .get(this.getApiUrl("is_associated_to_job?reminderId=" + id));
    };
    PropertyViewService.prototype.deleteServiceReminderRequest = function (params) {
        return this.http.post(this.getApiUrl("delete_customer_service_reminder"), params, { responseType: 'text' });
    };
    PropertyViewService.prototype.deleteCustomerContact = function (params) {
        return this.http.post(this.getApiUrl("customer_delete_contact"), params, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.deleteCustomerBranch = function (params) {
        return this.http.post(this.getApiUrl("delete_customerbranch"), params, { responseType: 'text' });
    };
    PropertyViewService.prototype.deleteCustomer = function (params) {
        return this.http.post(this.getApiUrl("delete_customer"), params, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.checkCustomerDeleteStatus = function (customerId) {
        return this.http.get(this.getApiUrl("is_customer_delete?id=" + customerId));
    };
    PropertyViewService.prototype.deleteCustomerFile = function (params) {
        return this.http.post(this.getApiUrl("remove_customer_file"), params, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.getTimelineDetails = function (screen, tablePKId, page, limit, search, userId, object, fromDate, toDate, activityId, skipIds) {
        if (page === void 0) { page = 1; }
        if (limit === void 0) { limit = 1; }
        if (skipIds === void 0) { skipIds = []; }
        var data = {
            page: page,
            limit: limit,
            search: search,
            userId: userId,
            object: object,
            fromDate: fromDate,
            toDate: toDate,
            activityId: activityId,
            skipIds: skipIds
        };
        var url = this.getApiUrl("activities/" + screen + "/" + tablePKId + "/view");
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.exportCustomerCsv = function (customerId) {
        var url = this.getApiUrl("customers/" + customerId + "/exportCsv");
        return this.http.get(url);
    };
    PropertyViewService.prototype.saveNote = function (id, type, tablePKId, screen, data) {
        var url;
        if (screen == 'jobs') {
            url = this.getApiUrl("customers/" + type + "/" + id + "/" + screen + "/" + tablePKId + "/notes/new/timeline?csrf=false");
        }
        else if (screen == 'customer') {
            url = this.getApiUrl(type + "/new_notes/" + id + "/timeline?csrf=false");
        }
        else if (screen === 'contract') {
            url = this.getApiUrl(type + "/new_notes/" + id + "/timeline?csrf=false");
        }
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.saveEditNote = function (data) {
        var url = this.getApiUrl("edit_note");
        return this.http.post(url, data, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.deleteNote = function (data) {
        var url = this.getApiUrl("customer_delete_notes");
        return this.http.post(url, data, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.saveCallLog = function (id, type, tablePKId, screen, data) {
        var url;
        if (screen == 'jobs') {
            url = this.getApiUrl("customers/" + type + "/" + id + "/notes/" + screen + "/" + tablePKId + "/new_phone_call/timeline?csrf=false");
        }
        else if (screen == 'customer') {
            url = this.getApiUrl(type + "/new_phone_call/" + id + "/timeline?csrf=false");
        }
        else if (screen === 'contract') {
            url = this.getApiUrl(type + "/new_phone_call/" + id + "/timeline?csrf=false");
        }
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.saveEditCallLog = function (data) {
        var url = this.getApiUrl("edit_calllog");
        return this.http.post(url, data, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.saveSms = function (id, type, tablePKId, screen, data) {
        var url;
        if (screen == 'customer') {
            url = this.getApiUrl(type + "/new_sms_message/" + id + "/timeline?csrf=false");
        }
        else if (screen == 'jobs') {
            url = this.getApiUrl("customers/" + type + "/" + id + "/jobs/" + tablePKId + "/new_sms/timeline?csrf=false");
        }
        else if (screen === 'contract') {
            url = this.getApiUrl(type + "/new_sms_message/" + id + "/timeline?csrf=false");
        }
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.getTimelineViewDetails = function (customerId) {
        var url = this.getApiUrl("timelineViewDetails/" + customerId + "?tableType=2&id=" + customerId);
        return this.http.get(url);
    };
    PropertyViewService.prototype.saveScheduledActivity = function (data) {
        var url = this.getApiUrl("saveScheduledActivity");
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.saveEditScheduledActivity = function (data, activityId) {
        var url = this.getApiUrl("rescheduleActivity/" + activityId);
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.completeOpportunityActivity = function (data) {
        var url = this.getApiUrl("completeOpportunityActivity");
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.deleteCall = function (communicationId) {
        var url = this.getApiUrl("deleteCallLog/" + communicationId);
        return this.http.get(url, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.deleteScheduleActivity = function (activityId) {
        var url = this.getApiUrl("deleteScheduleActivity/" + activityId);
        return this.http.get(url);
    };
    PropertyViewService.prototype.getTechnicalReferences = function (id) {
        var url = this.getApiUrl("customers/" + id + "/customertechnicalreferences");
        return this.http.get(url);
    };
    PropertyViewService.prototype.updateCustomerTechnicalReference = function (id, data) {
        return this.http.post(this.getApiUrl("customers/" + id + "/update_customer_technical_reference"), data);
    };
    PropertyViewService.prototype.setPrimaryContact = function (params) {
        return this.http.post(this.getApiUrl("primary_contact"), params, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.getUpcomingScheduledActivities = function (screen, tablePKId) {
        var url = this.getApiUrl("upcoming_activities/" + screen + "/" + tablePKId + "/view");
        return this.http.post(url, []);
    };
    PropertyViewService.prototype.getPinnedNotes = function (screen, tablePKId) {
        var url = this.getApiUrl("pinned_notes/" + screen + "/" + tablePKId + "/view");
        return this.http.get(url);
    };
    PropertyViewService.prototype.portalShared = function (Id) {
        var url = this.getApiUrl("isPortalShared/" + Id);
        return this.http.get(url);
    };
    PropertyViewService.prototype.customerPortalLink = function (id) {
        var url = this.getApiUrl("customers/" + id + "/getCustomerPortalLink?checkValid=1");
        return this.http.get(url);
    };
    PropertyViewService.prototype.ShareCustomerPortalAccess = function (id) {
        var url = this.getApiUrl("customers/" + id + "/getCustomerPortalLink");
        return this.http.get(url);
    };
    PropertyViewService.prototype.managePortalValue = function (id, data) {
        return this.http.post(this.getApiUrl("managePortalAccess/" + id), data);
    };
    PropertyViewService.prototype.removeCustomerPortalAccess = function (id) {
        var url = this.getApiUrl("removePortalAccess/" + id);
        return this.http.get(url);
    };
    PropertyViewService.prototype.checkStatementStatus = function (id, type) {
        var url = this.getApiUrl("customers/" + type + "/" + id + "/check_send_statement");
        return this.http.get(url);
    };
    PropertyViewService.prototype.saveSlaValue = function (id, slaId) {
        return this.http.post(this.getApiUrl("get_sla_details_post/" + id + "/" + slaId), '');
    };
    PropertyViewService.prototype.getSlaValue = function (id) {
        var url = this.getApiUrl("get_sla_details_records/" + id);
        return this.http.get(url);
    };
    PropertyViewService.prototype.editSlaValue = function (data) {
        return this.http.post(this.getApiUrl("edit_customer_sla"), data);
    };
    PropertyViewService.prototype.renameFiles = function (data) {
        return this.http.post(this.getApiUrl("rename_file"), data);
    };
    PropertyViewService.prototype.saveSelectedNavagiation = function (data) {
        var url = this.getApiUrl("userColumnOrder");
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.deleteAppliance = function (applianceId) {
        return this.http.post(this.getApiUrl("delete_appliance"), applianceId, { responseType: 'text' });
    };
    PropertyViewService.prototype.setCustomerAppliance = function (data) {
        this.customerAppliance.next(data);
    };
    PropertyViewService.prototype.getCustomerAppliance = function (id, type) {
        var url = this.getApiUrl("customers/" + type + "/" + id + "/appliances/views");
        return this.http.get(url);
    };
    PropertyViewService.prototype.setTimelineRefresh = function (data) {
        this.timelineRefresh.next(data);
    };
    PropertyViewService.prototype.setSliderNoteDetails = function (data) {
        this.sliderNoteDetails.next(data);
    };
    PropertyViewService.prototype.downloadTimeline = function (type, logIds, customerId) {
        var data = {
            type: type,
            logIds: logIds
        };
        var url = this.getApiUrl(customerId + "/download_timeline_excel");
        return this.http.post(url, data);
    };
    PropertyViewService.prototype.setDownloadTimeline = function (type) {
        this.timelineDownload.next(type);
    };
    PropertyViewService.prototype.getCustomerAssetsFilter = function (id, filterId) {
        var url = this.getApiUrl("getCustomerAssetFilter/" + id + "?filter=" + filterId);
        return this.http.get(url);
    };
    PropertyViewService.prototype.getCustomerAssetsFilterRecords = function (id, type, groupId, fieldType, filterId) {
        var url = this.getApiUrl("customers/" + type + "/" + id + "/assets/" + groupId + "/" + fieldType + "/" + filterId);
        return this.http.get(url);
    };
    PropertyViewService.prototype.updateWhoIsViewing = function (availableUsers) {
        this.whoIsViewing.next(availableUsers);
    };
    PropertyViewService.prototype.getUsersList = function () {
        var url = this.getApiUrl("get_clientUsers");
        return this.http.get(url);
    };
    PropertyViewService.prototype.getDoNotWorkStatus = function (customerid) {
        var url = this.getApiUrl("getDoNotWorkStatus/" + customerid);
        return this.http.get(url);
    };
    PropertyViewService.prototype.getTodayScheduleActivity = function (id, type, screen) {
        var url = this.getApiUrl("todayScheduledActivity/" + id + "/" + type + "/" + screen);
        return this.http.get(url);
    };
    PropertyViewService.prototype.checkIfCustomerAssetIsDeletable = function (customerId, customerType, assetId) {
        return this.http.get(this.getApiUrl("customers/" + customerType + "/" + customerId + "/assets/" + assetId + "/is-deletable"));
    };
    PropertyViewService.prototype.deleteCustomerAsset = function (id) {
        var body = new URLSearchParams();
        body.set('asset[id]', JSON.stringify(id));
        body.set('moduleValue[moduleId]', JSON.stringify(''));
        body.set('moduleValue[jobtodos]', JSON.stringify(''));
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.getApiUrl("delete_customer_asset"), body.toString(), { headers: headers, responseType: 'text' });
    };
    PropertyViewService.prototype.getCanWorkWithProperty = function (canWork) {
        this.canWorkWithCustomer.next(canWork);
    };
    PropertyViewService.prototype.setCanNotWorkWithProperty = function (canNotWork) {
        this.canNotWorkWithCustomer.next(canNotWork);
    };
    PropertyViewService.prototype.setTimelineDetails = function (details) {
        this.timelineDetails.next(details);
    };
    PropertyViewService.prototype.checkCustomerContact = function (contactId, customerId) {
        var url = this.getApiUrl("Checkdeletecustomercontacts/" + contactId + "/" + customerId);
        return this.http.get(url);
    };
    PropertyViewService.prototype.listingCustomerContact = function (customerId, contactId) {
        var url = this.getApiUrl("listCustomerContacts/" + customerId + "?id=" + contactId);
        return this.http.get(url);
    };
    PropertyViewService.prototype.downloadMultipleFiles = function (params) {
        return this.http.post(this.getApiUrl("download_multiple_files"), params, {
            responseType: 'text'
        });
    };
    PropertyViewService.prototype.setModalCloseEvent = function (data) {
        this.modalCloseEvent.next(data);
    };
    PropertyViewService.ngInjectableDef = i0.defineInjectable({ factory: function PropertyViewService_Factory() { return new PropertyViewService(i0.inject(i1.HttpClient)); }, token: PropertyViewService, providedIn: "root" });
    return PropertyViewService;
}(InterfaceService));
export { PropertyViewService };
