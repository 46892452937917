import * as tslib_1 from "tslib";
import { InterfaceService } from "@app/interfaces";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CsActivityLogApiService = /** @class */ (function (_super) {
    tslib_1.__extends(CsActivityLogApiService, _super);
    function CsActivityLogApiService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    // Note: This only tested and used for the format of the values of `{ key: val (array) }` and '{ key: val (string | number) }' like, ex:
    //      - `filterParams = {search: '', filters: [....]}`
    CsActivityLogApiService.prototype.getSerializedFilterParams = function (filterParams) {
        var queryParams = new HttpParams();
        Object.entries(filterParams).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (Array.isArray(value)) {
                if (value.length) {
                    value.forEach(function (filter) { return queryParams = queryParams.append(key + "[]", filter); });
                }
            }
            else {
                queryParams = queryParams.set(key, value);
            }
        });
        return queryParams;
    };
    CsActivityLogApiService.prototype.getActivityLogFilters = function (customerType, customerId, module, moduleId) {
        return this.http.get(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/timeline-config"));
    };
    CsActivityLogApiService.prototype.getActivityLogData = function (customerType, customerId, module, moduleId, filterParams) {
        // Note: This serialization used for our specific required format of params in payload,
        //      - like we want the request params should keep it's original format in back-end of { key: val (array), ex: { filters: [....] } }
        var params = this.getSerializedFilterParams(filterParams);
        return this.http.get(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/timeline"), { params: params });
    };
    CsActivityLogApiService.prototype.setFavouriteControls = function (customerType, customerId, module, moduleId, data) {
        return this.http.post(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/timeline-config"), JSON.stringify(data));
    };
    CsActivityLogApiService.prototype.getTimelinePreloadData = function (customerId, moduleId, tableType) {
        return this.http.get(this.getApiUrl("timelineViewDetails/" + customerId + "?tableType=" + tableType + "&id=" + customerId));
    };
    // Communications :
    CsActivityLogApiService.prototype.sendEmail = function (customerType, customerId, module, moduleId, data) {
        return this.http.post(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/emails"), data);
    };
    CsActivityLogApiService.prototype.sendSms = function (customerType, customerId, module, moduleId, data) {
        return this.http.post(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/messages"), data);
    };
    CsActivityLogApiService.prototype.saveCallLog = function (customerType, customerId, module, moduleId, data) {
        return this.http.post(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/calls"), data);
    };
    CsActivityLogApiService.prototype.updateCallLog = function (data) {
        return this.http.post(this.getApiUrl('edit_calllog'), data, { responseType: 'text' });
    };
    CsActivityLogApiService.prototype.deleteCallLog = function (communicationId) {
        return this.http.get(this.getApiUrl("deleteCallLog/" + communicationId), { responseType: 'text' });
    };
    CsActivityLogApiService.prototype.saveScheduledActivity = function (customerType, customerId, module, moduleId, data) {
        return this.http.post(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/scheduled-activities"), data);
    };
    CsActivityLogApiService.prototype.updateScheduledActivity = function (data, activityId) {
        return this.http.post(this.getApiUrl("rescheduleActivity/" + activityId), data);
    };
    CsActivityLogApiService.prototype.deleteScheduledActivity = function (activityId) {
        return this.http.get(this.getApiUrl("deleteScheduleActivity/" + activityId));
    };
    CsActivityLogApiService.prototype.saveNote = function (customerType, customerId, module, moduleId, data) {
        return this.http.post(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/notes"), data);
    };
    CsActivityLogApiService.prototype.updateNote = function (data) {
        return this.http.post(this.getApiUrl('edit_note'), data, { responseType: 'text' });
    };
    CsActivityLogApiService.prototype.deleteNote = function (data) {
        return this.http.post(this.getApiUrl('customer_delete_notes'), data, { responseType: 'text' });
    };
    // Pinned slider :
    CsActivityLogApiService.prototype.getUpcomingScheduledActivities = function (customerType, customerId, module, moduleId) {
        return this.http.get(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/scheduled-activities/upcoming"));
    };
    CsActivityLogApiService.prototype.getPinnedNotes = function (customerType, customerId, module, moduleId) {
        return this.http.get(this.getApiUrl("customers/" + customerType + "/" + customerId + "/" + module + "/" + moduleId + "/notes/pinned"));
    };
    CsActivityLogApiService.prototype.getTodayScheduleActivity = function (activityType, module, moduleId) {
        return this.http.get(this.getApiUrl("todayScheduledActivity/" + moduleId + "/" + activityType + "/" + module));
    };
    CsActivityLogApiService.ngInjectableDef = i0.defineInjectable({ factory: function CsActivityLogApiService_Factory() { return new CsActivityLogApiService(i0.inject(i1.HttpClient)); }, token: CsActivityLogApiService, providedIn: "root" });
    return CsActivityLogApiService;
}(InterfaceService));
export { CsActivityLogApiService };
