import {Component, Inject, ViewEncapsulation, LOCALE_ID, HostListener, forwardRef} from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Router, NavigationEnd, NavigationStart, ResolveEnd } from '@angular/router';
import { filter } from "rxjs/operators";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, esUsLocale } from 'ngx-bootstrap/locale';
import {TranslateService} from '@ngx-translate/core';
import {LicenseManager} from "ag-grid-enterprise";

//--
import { HybridFactory, SettingsService } from '@app/core';
import {CsToastBoxService, CsVersionService,SessionLogService} from '@app/services';
import { InterfaceClass } from './interfaces';
import { UPGRADED_ROUTES } from './config';
import {RenewalTokenService} from "@app/core/auth/renewal-token-service";
import {environment} from "../environments/environment";
import { SharedModalService } from './shared-modal/service/shared-modal.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: { '(window:callAngular7Function)': 'redirectToAngular7Module($event)' }
})
export class AppComponent extends InterfaceClass {
    showToastMessage: boolean = false;
    toastConfig: {
        message: string;
        duration: number;
    };
    isAuthenticated:boolean = false;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        @Inject('$scope') private ajsScope,
        @Inject( forwardRef( () => SettingsService) ) readonly settingsService: SettingsService,
        private toastBox: CsToastBoxService,
        private location: PlatformLocation,
        private router: Router,
        private bsLocaleService: BsLocaleService,
        public translate: TranslateService,
        private csVersionService: CsVersionService,
        private SessionLogService : SessionLogService,
        private sharedModalService: SharedModalService,

    ) {
        super();

        // Import locale settings for possible languages
        enGbLocale.week.dow = 0; // set sunday as week start
        defineLocale('en-gb', enGbLocale);

        //setting default language for the angular application
        translate.setDefaultLang('en');
        translate.use(settingsService.getCsLanguage());

        // Set bootstrap datepicker locale
        const settingsLang = settingsService.getCsLanguage();

        let language = settingsService.getCsLanguage();
        if(language == 'en' || language == null || language == undefined) {
            language = 'en-GB';
        }

        this.bsLocaleService.use(language);
        let self = this;
        // Updating permissions on login success, pulling data from AngularJs
        this.ajsScope.$on('permissionsChanged', function (e, data) {
            if(data && data.hasOwnProperty('accessDetails') && Object.keys(data.accessDetails).length){
                self.isAuthenticated = true;
            }
            else{
                self.isAuthenticated = false;
                let emailData = {
                    showModal: false,
                }
                self.sharedModalService.setEmailModalDetails(emailData);
                const activityData = { showActivityModal: false };
                self.sharedModalService.setScheduledActivityDetails(emailData);
            }
            settingsService.setAccessDetails(data);
        });

        // Updating module details on login success, pulling data from AngularJs
        this.ajsScope.$on('modulesChanged', function (e, data) {
            settingsService.setModulePermissions(data);
        });

        // Hooking router events, for hiding angularJs content after the angularES data resolve
        this.router.events
          .pipe(filter(event => event instanceof ResolveEnd))
          .subscribe(event => {
              HybridFactory.onLocationChangeSuccess(event, 'NG2', this.ajsScope);
          });

        // AG-GRID License setup
        this.setCsAgGridLicense();
    }

    ngOnInit() {
        // @ts-ignore
        //get userdetails on csVersionServices if null get and set value in this.ajsScope
        const { clientId, username } = this.csVersionService.getuserDeatails() || {};
        const userDetaisls = {
            clientId: clientId || this.ajsScope.clientId,
            username: username || this.ajsScope.username,
        };

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                let startTime = Date.now();
                this.SessionLogService.sessionStartTime(startTime);

            }
            if (event instanceof NavigationEnd) {
                let endTime = Date.now();
                this.SessionLogService.sessionEndTime(endTime);
                let sessionDuration = this.SessionLogService.getDuration();
                if (clientId && username && event.url && sessionDuration != null){
                    this.SessionLogService.addToSessionLog(clientId,username, event.url, sessionDuration).subscribe(res => {
                        // console.log(res);
                    });
                }
            }
        });

        // Watch for toast message controll action
        this.subscriptions['toastControl'] = this.toastBox.toastControl$.subscribe(data => {
            if (!data) return;

            if (data.action == 'show') {
                this.toastConfig = {
                    message: data.message,
                    duration: data.duration
                };
                setTimeout(() => {
                    this.showToastMessage = true;
                }, 100)
            }

            if (data.action == 'hide') {
                this.toastConfig = { message: null, duration: null };
                setTimeout(() => {
                    this.showToastMessage = false;
                }, 100)
            }
        });
    }

    // Window keyup listener for application shortcuts
    @HostListener('window:keyup', ['$event'])
    onKeyup(event: KeyboardEvent){
        this.ajsScope.keyup(event);
    }

    redirectToAngular7Module(data?) {

        let callURL: string;
        if (data) {
            callURL = data.detail.url
        }

        // had to duplicate this code because could'nt import from hybrid-factory.ts file
        let presentInUpgradedList = UPGRADED_ROUTES.find(pathPattern => {
            return (new RegExp(pathPattern, 'g')).test(window.location.pathname);
        });

        if (presentInUpgradedList) {
            if (window.location.pathname !== '/settings' && window.location.pathname !== '/company_settings/integrations') {
            this.router.navigateByUrl(callURL)

            }
        }
    }

    setCsAgGridLicense() {
        LicenseManager.setLicenseKey(environment.agGridEnterpriseLicenseKey);
    }
}
