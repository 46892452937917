import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {InterfaceService} from "@app/interfaces";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ICsActivityLogFilterParams} from "@app/shared/cs-components/cs-components.interface";

@Injectable({
    providedIn: 'root'
})
export class CsActivityLogApiService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    // Note: This only tested and used for the format of the values of `{ key: val (array) }` and '{ key: val (string | number) }' like, ex:
    //      - `filterParams = {search: '', filters: [....]}`

    getSerializedFilterParams(filterParams: ICsActivityLogFilterParams): HttpParams {
        let queryParams = new HttpParams();

        Object.entries(filterParams).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length) {
                    value.forEach(filter => queryParams = queryParams.append(`${key}[]`, filter));
                }
            } else {
                queryParams = queryParams.set(key, value);
            }
        });

        return queryParams;
    }

    getActivityLogFilters(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number
    ): Observable<any> {
        return this.http.get(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/timeline-config`));
    }

    getActivityLogData(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number,
        filterParams?: ICsActivityLogFilterParams
    ): Observable<any> {

        // Note: This serialization used for our specific required format of params in payload,
        //      - like we want the request params should keep it's original format in back-end of { key: val (array), ex: { filters: [....] } }

        const params = this.getSerializedFilterParams(filterParams);
        return this.http.get(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/timeline`), {params});
    }

    setFavouriteControls(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number,
        data
    ): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/timeline-config`), JSON.stringify(data));
    }

    getTimelinePreloadData(
        customerId: number,
        moduleId: number,
        tableType: number
    ): Observable<any> {
        return this.http.get(this.getApiUrl(`timelineViewDetails/${customerId}?tableType=${tableType}&id=${customerId}`));
    }

    // Communications :

    sendEmail(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number,
        data
    ): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/emails`), data);
    }

    sendSms(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number,
        data
    ): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/messages`), data);
    }

    saveCallLog(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number,
        data
    ): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/calls`), data);
    }

    updateCallLog(data): Observable<any> {
        return this.http.post(this.getApiUrl('edit_calllog'), data, {responseType: 'text'});
    }

    deleteCallLog(communicationId: number): Observable<any> {
        return this.http.get(this.getApiUrl(`deleteCallLog/${communicationId}`), {responseType: 'text'});
    }

    saveScheduledActivity(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number,
        data
    ): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/scheduled-activities`), data);
    }

    updateScheduledActivity(data, activityId: number): Observable<any> {
        return this.http.post(this.getApiUrl(`rescheduleActivity/${activityId}`), data);
    }

    deleteScheduledActivity(activityId): Observable<any> {
        return this.http.get(this.getApiUrl(`deleteScheduleActivity/${activityId}`));
    }

    saveNote(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number,
        data
    ): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/notes`), data);
    }

    updateNote(data): Observable<any> {
        return this.http.post(this.getApiUrl('edit_note'), data, {responseType: 'text'});
    }

    deleteNote(data): Observable<any> {
        return this.http.post(this.getApiUrl('customer_delete_notes'), data, {responseType: 'text'});
    }

    // Pinned slider :
    getUpcomingScheduledActivities(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number
    ): Observable<any> {
        return this.http.get(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/scheduled-activities/upcoming`));
    }

    getPinnedNotes(
        customerType: string,
        customerId: number,
        module: string,
        moduleId: number
    ): Observable<any> {
        return this.http.get(this.getApiUrl(`customers/${customerType}/${customerId}/${module}/${moduleId}/notes/pinned`));
    }

    getTodayScheduleActivity(
        activityType,
        module: string,
        moduleId: number
    ): Observable<any> {
        return this.http.get(this.getApiUrl(`todayScheduledActivity/${moduleId}/${activityType}/${module}`));
    }
}
