import { BehaviorSubject } from 'rxjs';
import { CsActivityLogApiService } from '@app/shared/cs-activity-log/cs-activity-log-api.service';
import { map } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import * as i0 from "@angular/core";
import * as i1 from "./cs-activity-log-api.service";
var CsActivityLogEventService = /** @class */ (function () {
    function CsActivityLogEventService(_alApi) {
        this._alApi = _alApi;
        this.tableType = 2;
        this.activityLogData = new BehaviorSubject(null);
        this.onActivityLogEvent$ = this.activityLogData.asObservable();
        this.activityEventData = new BehaviorSubject(null);
        this.onActivityEvent$ = this.activityEventData.asObservable();
        this.timelinePreloadData = new BehaviorSubject({});
        this.onTimelinePreloadDataEvent$ = this.timelinePreloadData.asObservable().pipe(map(function (value) {
            return {
                assignee: value['assignee'] || [],
                attachedFiles: value['attachedFiles'] || [],
                activityTypes: value['activityType'] || []
            };
        }));
        this.refreshPinnedNotes = new Subject();
        this.onRefreshPinnedNotes$ = this.refreshPinnedNotes.asObservable();
        this.refreshUpcomingActivities = new Subject();
        this.onRefreshUpcomingActivities$ = this.refreshUpcomingActivities.asObservable();
        this.pinnedNoteData = new Subject();
        this.onViewPinnedNote$ = this.pinnedNoteData.asObservable();
        this.scheduledActivityCompletionData = new Subject();
        this.onScheduledActivityCompletion$ = this.scheduledActivityCompletionData.asObservable();
        this.externalEvent = new Subject();
        this.onExternalEvent$ = this.externalEvent.asObservable();
    }
    CsActivityLogEventService.prototype.setInitialParams = function (customerType, customerId, module, moduleId) {
        this.customerType = customerType;
        this.customerId = customerId;
        this.module = module;
        this.moduleId = moduleId;
    };
    CsActivityLogEventService.prototype.notifyUpdateActivityLogData = function (eventParams, event) {
        this.setActivityLogData(eventParams, event);
    };
    CsActivityLogEventService.prototype.notifyEditActivityEvent = function (data) {
        this.activityEventData.next({ eventData: data, event: 'EDIT' });
    };
    CsActivityLogEventService.prototype.notifyPostEditActivity = function (data) {
        this.activityLogData.next({ eventData: data, event: 'ACTIVITY_EDIT' });
    };
    CsActivityLogEventService.prototype.notifyDeleteActivityEvent = function (data) {
        this.activityEventData.next({ eventData: data, event: 'DELETE' });
    };
    CsActivityLogEventService.prototype.notifyPostDeleteActivity = function (data) {
        this.activityLogData.next({ eventData: data, event: 'ACTIVITY_DELETE' });
    };
    CsActivityLogEventService.prototype.notifyRefreshPinnedNotes = function () {
        this.refreshPinnedNotes.next();
    };
    CsActivityLogEventService.prototype.notifyRefreshUpcomingActivities = function () {
        this.refreshUpcomingActivities.next();
    };
    CsActivityLogEventService.prototype.notifyViewPinnedNote = function (data) {
        this.pinnedNoteData.next(data);
    };
    CsActivityLogEventService.prototype.notifyScheduledActivityCompletion = function (data) {
        this.scheduledActivityCompletionData.next(data);
    };
    CsActivityLogEventService.prototype.notifyExternalEvent = function (params) {
        this.externalEvent.next(params);
    };
    CsActivityLogEventService.prototype.setActivityLogData = function (filterParams, event) {
        var _this = this;
        this.getActivityLogData(filterParams).subscribe(function (res) {
            _this.activityLogData.next({ eventData: res, event: event });
        });
    };
    CsActivityLogEventService.prototype.getActivityLogData = function (filterParams) {
        return this._alApi.getActivityLogData(this.customerType, this.customerId, this.module, this.moduleId, filterParams);
    };
    CsActivityLogEventService.prototype.setTimelinePreloadData = function () {
        var _this = this;
        this._alApi.getTimelinePreloadData(this.customerId, this.moduleId, this.tableType).subscribe(function (res) {
            _this.timelinePreloadData.next(res);
        });
    };
    CsActivityLogEventService.prototype.resetActivityLogData = function () {
        this.activityLogData.next({ eventData: null, event: null });
    };
    CsActivityLogEventService.prototype.resetActivityEventData = function () {
        this.activityEventData.next(null);
    };
    CsActivityLogEventService.ngInjectableDef = i0.defineInjectable({ factory: function CsActivityLogEventService_Factory() { return new CsActivityLogEventService(i0.inject(i1.CsActivityLogApiService)); }, token: CsActivityLogEventService, providedIn: "root" });
    return CsActivityLogEventService;
}());
export { CsActivityLogEventService };
